<template>
  <sb-page-content title="Create Service Request">
    <v-card hover
            tile
            class="noclick"
            max-height="600px">
      <v-row>
        <v-col sm="auto"
               class="py-0">
          <v-stepper v-model="step"
                     vertical
                     class="sb-tile sb-blue-gradient sb-stepper">
            <v-stepper-header class="elevation-0">
              <v-row justify="center"
                     align="center">
                <span class="white--text">{{ step }} / 6</span>
              </v-row>
            </v-stepper-header>
            <template v-for="n in 6">
              <v-stepper-step :key="`${n}-step`"
                              :complete="step > n"
                              :step="n"
                              color="#F86701" />

              <v-stepper-content :key="`${n}-content`"
                                 :step="n" />
            </template>
          </v-stepper>
        </v-col>
        <v-col>
          <v-card flat
                  tile
                  class="elevation-0">
            <v-card-title class="text-h4">
              <div>
                <div class="sb-darkgrey--text">
                  {{ currentTitle }}
                </div>
                <div class="title-gradient" />
              </div>
            </v-card-title>

            <div class="sb-create-sr-card">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-card-text class="py-0">
                    <v-form>
                      <v-row justify="center">
                        <v-col cols="12"
                               md="8">
                          <sb-select v-model="serviceRequest.requestType.key"
                                     :items="requestTypes"
                                     label="Request Type"
                                     item-value="key"
                                     item-text="value"
                                     rules="required" />
                          <sb-select v-model="serviceRequest.serviceType.key"
                                     :items="serviceTypes"
                                     label="Service Type"
                                     item-value="key"
                                     item-text="value"
                                     @change="serviceTypeChanged" />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="py-0">
                    <v-row justify="center">
                      <v-col cols="12"
                             md="8">
                        <sb-vehicle-search @vehicle-update="updateVehicle" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="py-0">
                    <v-row justify="center">
                      <v-col cols="12"
                             md="8">
                        <sb-merchant-search v-if="selectedCallerType === CALLER_TYPES.MERCHANT.value"
                                            centered
                                            @merchant-update="updateMerchant" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="py-0">
                    <v-row justify="center">
                      <v-col cols="12"
                             md="8">
                        <sb-numeric-field v-model.number="serviceRequest.vehicleDetails.odometerReading"
                                          label="Confirm Vehicle Odometer Reading"
                                          placeholder="100000" />

                        <sb-checkbox v-if="serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_INCIDENT_MANAGEMENT.key"
                                     v-model="serviceRequest.vehicleDriveable"
                                     label="Is Vehicle Drivable?"
                                     hide-details />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="py-0">
                    <v-row justify="center">
                      <v-col cols="12"
                             md="10">
                        <div class="text-caption mb-3">
                          Vehicle Details
                        </div>
                        <v-row>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Client Name"
                                      :value="serviceRequest.customer.name" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Registration Number"
                                      :value="serviceRequest.vehicle.registrationNumber" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Model Description"
                                      :value="serviceRequest.vehicle.model" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Odometer reading"
                                      :value="serviceRequest.vehicleDetails.odometerReading" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Cost Centre"
                                      :value="serviceRequest.customer.costCentre" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Vehicle VIN Number"
                                      :value="serviceRequest.vehicle.vehicleIdentificationNumber" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input label="Vehicle Mass"
                                      :value="serviceRequest.vehicle.mass" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12"
                             md="10">
                        <div class="text-caption mb-3">
                          Ticket Details
                        </div>
                        <v-row>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input v-if="REQUEST_TYPE_LIST[serviceRequest.requestType.key]"
                                      label="Request Type"
                                      :value="REQUEST_TYPE_LIST[serviceRequest.requestType.key].value" />
                          </v-col>
                          <v-col cols="12"
                                 sm="6">
                            <sb-input v-if="SERVICE_TYPE_LIST[serviceRequest.serviceType.key]"
                                      label="Service Type"
                                      :value="SERVICE_TYPE_LIST[serviceRequest.serviceType.key].value" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="12"
                             sm="8"
                             class="d-flex justify-center">
                        <v-img src="/img/animations/form-complete.gif"
                               max-width="280" />
                      </v-col>
                      <v-col cols="12"
                             sm="8"
                             class="text-center mt-5">
                        <div>
                          <div class="text-caption mb-3">
                            Your Reference Number
                          </div>
                          <div class="big-number">
                            {{ serviceRequest.serviceRequestNumber }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-window-item>
              </v-window>
            </div>

            <v-card-actions>
              <v-btn v-if="step !== 6"
                     :disabled="step === 1"
                     outlined
                     large
                     color="secondary"
                     @click="step--">
                Back
              </v-btn>
              <v-spacer />
              <v-btn v-if="step < 5"
                     :disabled="isNextDisabled || isOdometerInvalid"
                     color="secondary"
                     depressed
                     large
                     @click="step++">
                Next
              </v-btn>
              <v-btn v-if="step === 5"
                     :disabled="$wait.any"
                     color="success"
                     depressed
                     large
                     :loading="$wait.is('serviceRequestLoading')"
                     @click="createNewServiceRequest">
                Create Request
              </v-btn>
              <v-btn v-if="step === 6"
                     depressed
                     large
                     color="secondary"
                     @click="serviceRequestDone()">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </sb-page-content>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import { REQUEST_TYPE_LIST, SERVICE_TYPE_LIST, CALLER_TYPES, SR_APPLICATION_TYPES } from '@/config/constants';

import SbVehicleSearch from '@/components/vehicle/SbVehicleSearch';
import SbMerchantSearch from '@/components/merchant/SbMerchantSearch';

import ServiceRequest from '@/models/ServiceRequest';

export default {
  components: {
    SbVehicleSearch,
    SbMerchantSearch,
  },

  data() {
    return {
      REQUEST_TYPE_LIST,
      SERVICE_TYPE_LIST,
      CALLER_TYPES,
      selectedCallerType: CALLER_TYPES.MERCHANT.value,
      serviceRequest: new ServiceRequest(),
      step: 1,

      vehicleResultVisible: false,
      merchantResultVisible: false,

      callerSelectionDisabled: false,

      isOdometerInvalid: false,
    };
  },

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Service Details';
        case 2:
          return 'Select Vehicle';
        case 3:
          return 'Merchant Details';
        case 4:
          return 'Vehicle Details';
        case 5:
          return 'Summary';
        case 6:
          return 'Complete';
        default:
          return '';
      }
    },

    requestTypes() {
      return Object.values(REQUEST_TYPE_LIST).filter((x) => !x.hidden);
    },

    serviceTypes() {
      return Object.values(SERVICE_TYPE_LIST).filter((x) => !x.hidden);
    },

    callerTypes() {
      return Object.values(CALLER_TYPES);
    },

    isNextDisabled() {
      let disabled = false;

      if (this.step === 2 && !this.vehicleResultVisible) {
        disabled = true;
      }

      if (this.step === 3 && !this.merchantResultVisible) {
        disabled = true;
      }

      return this.$wait.any || disabled;
    },

    odometerReading() {
      return this.serviceRequest.vehicleDetails.odometerReading;
    },
  },

  watch: {
    selectedCallerType(value) {
      if (value !== SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key) {
        this.selectedMerchantResult = null;
        this.updateMerchantResult();
      }
    },

    odometerReading() {
      if (this.step === 4) {
        if (this.serviceRequest.vehicleDetails.odometerReading < this.serviceRequest.vehicle.odometerReading) {
          this.isOdometerInvalid = true;
        } else {
          this.isOdometerInvalid = false;
        }
      }
    },
  },

  beforeMount() {
    // Set some defaults
    this.serviceRequest.requestType.key = this.requestTypes[0].key;
    this.serviceRequest.serviceType.key = this.serviceTypes[0].key;
  },

  methods: {
    ...mapActions('serviceRequests', ['createServiceRequest']),

    ...mapWaitingActions('serviceRequests', {
      createServiceRequest: 'serviceRequestLoading',
    }),

    updateVehicle(value) {
      this.serviceRequest.vehicle = value.vehicle;
      this.serviceRequest.vehicleDetails = value.vehicleDetails;
      this.serviceRequest.customer = value.customer;
      this.vehicleResultVisible = value.vehicleResultVisible;
    },

    updateMerchant(value) {
      this.serviceRequest.merchant = value.merchant;
      this.merchantResultVisible = value.merchantResultVisible;
    },

    async createNewServiceRequest() {
      this.serviceRequest.applicationType.key = SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_SLAM;
      this.serviceRequest.createdBy = this.authUser.username;
      this.serviceRequest.currentTask = 'Maintenance Authorisation';
      this.serviceRequest.serviceRequestNumber = await this.createServiceRequest(this.serviceRequest);

      if (this.serviceRequest.serviceRequestNumber) {
        this.step += 1;
      }
    },

    serviceTypeChanged(value) {
      if (value === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key) {
        this.selectedCallerType = CALLER_TYPES.MERCHANT.value;
        this.callerSelectionDisabled = true;
      } else {
        this.selectedCallerType = CALLER_TYPES.CUSTOMER.value;
        this.callerSelectionDisabled = false;
      }
    },

    serviceRequestDone() {
      if (this.serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key) {
        this.$store.commit('serviceRequests/showServiceRequestTasks', false);
      } else {
        this.$store.commit('serviceRequests/showServiceRequestTasks', true);
      }
      const serviceRequestNumber = `${this.serviceRequest.serviceRequestNumber}`;

      this.$router.push({
        name: 'SlaManagementServiceRequest',
        params: { serviceRequestNumber },
      });
    },
  },
};
</script>
