<template>
  <div>
    <v-col v-if="$wait.is('eventsLoading')"
           cols="12"
           class="py-0">
      <v-row justify="center">
        <sb-loader class="mt-5" />
      </v-row>
    </v-col>

    <v-col v-else
           cols="12"
           class="py-0">
      <sb-empty-message v-if="events.length === 0"
                        message="No Events"
                        sub-message="No Events available at this time" />

      <v-timeline v-else>
        <v-timeline-item v-for="(item, index) in events"
                         :key="index"
                         :color="getSeverityColor(item.severity)"
                         right>
          <span slot="opposite">{{ item.timestamp }}</span>
          <v-card class="elevation-2 noclick"
                  hover>
            <v-card-title class="text-body-1">
              {{ item.event }}
            </v-card-title>
            <v-card-text>
              <div>
                <div>
                  Severity: {{ item.severity | startCase }}
                </div>
                <template v-if="item.severity === SEVERITIES.HIGH.value">
                  <div class="mt-2 mb-0">
                    Escalation Reasons:
                  </div>
                  <sb-empty-message v-if="item.comments.length === 0"
                                    sub-message="No Reasons"
                                    dense />

                  <div v-else
                       class=".text-h6">
                    <v-list dense>
                      <template v-for="(comment, commentIndex) in item.comments">
                        <v-list-item :key="`comment-item-${commentIndex}`">
                          <v-list-item-content>
                            <v-row>
                              <v-col cols="6"
                                     class="py-0">
                                {{ comment.user.displayName }}:
                              </v-col>
                              <v-col cols="6"
                                     class="text-right py-0">
                                {{ comment.timestamp }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12"
                                     class="text-body-2 py-0">
                                {{ comment.message }}
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="commentIndex + 1 < item.comments.length"
                                   :key="`comment-item-divider-${commentIndex}`" />
                      </template>
                    </v-list>
                  </div>

                  <sb-text-field :ref="`comment-${index}`"
                                 v-model="item.comment"
                                 :disabled="$wait.any"
                                 hide-details
                                 @enter="saveComment(item, index)" />

                  <div class="text-right mt-2">
                    <v-btn color="secondary"
                           :disabled="$wait.any"
                           :loading="$wait.is('updateEventLoading')"
                           depressed
                           @click="saveComment(item, index)">
                      Comment
                    </v-btn>
                  </div>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-col>
  </div>
</template>
<script>
import _cloneDeep from 'lodash/cloneDeep';
import _trimStart from 'lodash/trimStart';
import _trimEnd from 'lodash/trimEnd';
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import { SEVERITY_COLORS, SEVERITIES } from '@/config/constants';
import { updateStoreArrayItem } from '@/utils/storeUtils';

import Comment from '@/models/Comment';

export default {
  components: {},

  props: {
    referenceId: {
      type: String,
      default: '',
    },
    eventType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      SEVERITIES,
    };
  },

  computed: {
    ...mapGetters({
      events: 'events/events',
    }),
  },

  watch: {
    referenceId: {
      handler(value) {
        const eventsPayload = {
          referenceId: value,
          type: this.eventType,
        };
        this.getEvents(eventsPayload);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('events', ['getEvents', 'updateEvent']),

    ...mapWaitingActions('events', {
      getEvents: 'eventsLoading',
      updateEvent: 'updateEventLoading',
    }),

    getSeverityColor(severity) {
      return SEVERITY_COLORS[severity];
    },

    async saveComment(currentEvent, eventIndex) {
      if (!currentEvent.comment || currentEvent.comment.trim().length === 0) {
        return;
      }

      const event = _cloneDeep(currentEvent);

      // Build the new comment
      const newComment = new Comment();
      newComment.message = _trimStart(_trimEnd(event.comment));
      newComment.user.username = this.authUser.username;
      newComment.user.displayName = this.authUser.displayName;

      // Push the new comment to the event comments
      // and clean up the temp comment poperty
      event.comments.push(newComment);
      delete event.comment;

      // Update the events vuex store so the screen can be reactive
      updateStoreArrayItem('events/events', event);

      // Clear the textfield
      this.$refs[`comment-${eventIndex}`][0].innerValue = '';

      try {
        // Push the update to the back
        await this.updateEvent(event);
      } catch (error) {
        // On any failure roll back the event to its initial state
        // Assumptions:
        // If error.response is undefined, we treat this as a possible reason
        // of a timeout
        if (!error.response || (error.response && error.response.status !== 200)) {
          const revertEvent = _cloneDeep(currentEvent);
          delete revertEvent.comment;
          updateStoreArrayItem('events/events', revertEvent);
        }
      }
    },
  },
};
</script>
