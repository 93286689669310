<template>
  <sb-page-content title="Service Request"
                   class="back-button-margin">
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12">
          <sb-service-request-detail :service-request="serviceRequest"
                                     :loading="$wait.is('serviceRequestLoading')" />
        </v-col>
        <template v-if="showServiceRequestTasks">
          <v-col cols="12"
                 class="py-0">
            <div class="text-h2 my-5">
              Tasks
            </div>
          </v-col>

          <v-row v-if="$wait.is('getServiceRequestTasksLoading')"
                 justify="center">
            <sb-loader />
          </v-row>

          <v-col v-else
                 cols="12"
                 class="py-0">
            <sb-list :items="filteredItems"
                     :loading="$wait.is('serviceRequestsLoading')"
                     :service-request-number="serviceRequestNumber"
                     empty-message="No Service Requests"
                     empty-sub-message="No Service Requests available at this time or try relaxing your search parameters"
                     list-item-component="SbServiceRequestTaskListItem"
                     hide-pagination
                     @item-click="selectTask" />
          </v-col>
        </template>
        <v-col v-if="isManagedAuthorisationRequired"
               cols="12">
          <div class="text-h2 my-5">
            Managed Authorisations
          </div>

          <sb-authorisation-capture v-model="serviceRequest.managedAuthorisation"
                                    :audit="serviceRequest.audit"
                                    :disabled-complete="hasOpenTasks"
                                    :disabled="isServiceRequestClosed"
                                    :service-request-number="serviceRequestNumber"
                                    @click-save="saveServiceRequest"
                                    @click-complete="saveAndCompleteServiceRequest" />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isLinkedServiceRequest"
               cols="12">
          <work-authorisation-page :service-request-number="linkedServiceRequestNumber"
                                   :is-linked-service-request="isLinkedServiceRequest" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <sb-back-button url="/sla-management/service-requests"
                          tooltip="Return to Inbox" />
        </v-col>
      </v-row>
    </v-container>
  </sb-page-content>
</template>

<script>
import SbList from '@/components/core/SbList';

import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbServiceRequestDetail from '@/components/serviceRequest/SbServiceRequestDetail';
import SbAuthorisationCapture from '@/components/authorisation/SbAuthorisationCapture';
import SbBackButton from '@/components/core/SbBackButton';
import WorkAuthorisationPage from '@/views/electronicAuthorisations/workAuthorisations/WorkAuthorisationPage';

import { SR_STATUSES, SERVICE_TYPE_LIST } from '@/config/constants';
import { EventBus } from '@/utils/event-bus';
import ServiceRequest from '../../../models/ServiceRequest';

export default {
  components: {
    SbList,
    SbServiceRequestDetail,
    SbAuthorisationCapture,
    SbBackButton,
    WorkAuthorisationPage,
  },

  props: {
    serviceRequestNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serviceRequestTasks: [],
      isLinkedServiceRequest: false,
      linkedServiceRequestNumber: '',
      serviceRequest: new ServiceRequest(),
    };
  },
  computed: {
    ...mapGetters({
      showServiceRequestTasksIndicator: 'serviceRequests/showServiceRequestTasks',
    }),

    showServiceRequestTasks() {
      if (this.serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key) {
        this.$store.commit('serviceRequests/showServiceRequestTasks', false);
      }

      return (
        this.showServiceRequestTasksIndicator ||
        this.serviceRequest.serviceType.key !== SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key
      );
    },
    filteredItems() {
      return this.serviceRequestTasks;
    },

    hasOpenTasks() {
      let hasOpenTasks = false;

      if (!this.isManagedAuthorisation) {
        hasOpenTasks = this.serviceRequestTasks.some((x) => x.status.value.toLowerCase() === 'open');
      }

      return hasOpenTasks;
    },

    isManagedAuthorisationRequired() {
      return this.serviceRequest.managedAuthorisation && this.serviceRequest.managedAuthorisation.isRequired;
    },

    isServiceRequestClosed() {
      return (
        this.serviceRequest.status.key === SR_STATUSES.SR_STATUS_CLOSED.value ||
        this.serviceRequest.status.key === SR_STATUSES.SR_STATUS_COMPLETED.value
      );
    },
  },

  watch: {
    'serviceRequest.authorisation.linkedServiceRequestNumber': {
      handler() {
        this.isLinkedServiceRequest = this.serviceRequest.authorisation.linkedServiceRequestNumber !== undefined;
        this.linkedServiceRequestNumber = this.serviceRequest.authorisation.linkedServiceRequestNumber;
      },
    },
  },

  async created() {
    this.serviceRequest = await this.getServiceRequest(this.serviceRequestNumber);
    this.serviceRequestTasks = await this.getServiceRequestTasks({ serviceRequestNumber: this.serviceRequestNumber });
  },

  mounted() {
    EventBus.$on('upload-completed', () => {
      this.updateServiceRequest(this.serviceRequest);
    });
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequest', 'getServiceRequestTasks', 'updateServiceRequest', 'completeServiceRequest']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequest: 'serviceRequestLoading',
      getServiceRequestTasks: 'getServiceRequestTasksLoading',
      updateServiceRequest: 'saveServiceRequestLoading',
      completeServiceRequest: 'completeServiceRequestLoading',
    }),

    async saveServiceRequest() {
      this.serviceRequest.serviceRequestNumber = this.serviceRequestNumber;
      await this.updateServiceRequest(this.serviceRequest);
    },

    async saveAndCompleteServiceRequest() {
      this.serviceRequest.serviceRequestNumber = this.serviceRequestNumber;
      await this.completeServiceRequest(this.serviceRequest);

      this.$router.push({ name: 'SlaManagementServiceRequestsInbox' });
    },

    selectTask(item) {
      this.$router.push({
        name: 'SlaManagementServiceRequestTask',
        params: {
          serviceRequestNumber: this.serviceRequestNumber,
          taskId: item.id,
        },
      });
    },
  },
};
</script>
