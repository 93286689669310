<template>
  <sb-page-content title="Service Requests">
    <v-container class="pa-0">
      <v-toolbar dark
                 color="secondary"
                 class="mb-2 toolbar-rounded search-padding">
        <v-text-field v-model="search"
                      clearable
                      flat
                      solo
                      light
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      @click:clear="clearSearch" />
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{on}">
              <div v-on="on">
                <v-select v-model="filterStatus"
                          :disabled="$wait.any"
                          flat
                          solo
                          light
                          hide-details
                          :items="serviceRequestStatuses"
                          label="Status" />
              </div>
            </template>
            <span>Status</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-btn icon
                     class="mx-3"
                     v-on="on"
                     @click="refreshList">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </template>
      </v-toolbar>

      <sb-list :items="serviceRequests"
               :loading="$wait.is('serviceRequestsLoading')"
               :page.sync="pagination.number"
               :items-per-page.sync="pagination.numberOfElements"
               :total-pages="pagination.totalPages"
               empty-message="No Service Requests"
               empty-sub-message="No Service Requests available at this time or try relaxing your search parameters"
               list-item-component="SbServiceRequestListItem" />
    </v-container>
  </sb-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbList from '@/components/core/SbList';

import { SR_STATUSES, SR_APPLICATION_TYPES } from '@/config/constants';

import Pagination from '@/models/Pagination';

export default {
  components: {
    SbList,
  },

  data() {
    return {
      search: '',
      filterStatus: 'SR_STATUS_OPEN',
      pagination: new Pagination(),
    };
  },

  computed: {
    ...mapGetters({
      serviceRequests: 'serviceRequests/serviceRequests',
    }),

    serviceRequestStatuses() {
      return Object.values(SR_STATUSES).filter((x) => !x.hidden);
    },
  },

  watch: {
    filterStatus() {
      this.getServiceRequestsFiltered();
    },

    search(value) {
      // eslint-disable-next-line
      value && this.getServiceRequestsFiltered();
    },

    'pagination.number': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },

    'pagination.numberOfElements': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },
  },

  async created() {
    await this.getServiceRequestsFiltered();
  },

  beforeDestroy() {
    this.$store.commit('serviceRequests/serviceRequests', []);
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequests']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequests: 'serviceRequestsLoading',
    }),

    clearSearch() {
      this.search = '';
      this.getServiceRequestsFiltered();
    },

    async getServiceRequestsFiltered() {
      const result = await this.getServiceRequests({
        status: this.filterStatus,
        srType: SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_SLAM,
        authUser: this.authUser,
        filterValue: this.search || '',
        page: this.pagination.number,
        pageSize: this.pagination.numberOfElements,
      });

      this.pagination = result.pagination;

      return result;
    },

    refreshList() {
      this.getServiceRequestsFiltered();
    },

  },
};
</script>
