<template>
  <div>
    <v-col v-if="isLoading"
           cols="12"
           class="py-0">
      <v-row justify="center">
        <sb-loader class="mt-5" />
      </v-row>
    </v-col>

    <v-col v-else
           cols="12"
           class="py-0">
      <sb-empty-message v-if="auditLogs.length === 0"
                        message="No Audit Logs"
                        sub-message="No Audit Logs available at this time" />

      <v-timeline v-else>
        <v-timeline-item v-for="(item, index) in auditLogs"
                         :key="index"
                         :color="getAuditColor(item.status)"
                         right>
          <span slot="opposite">{{ item.createdTimestamp }}</span>
          <v-card class="elevation-2 noclick"
                  hover>
            <v-card-title class="text-body-1">
              {{ item.event }}
            </v-card-title>
            <v-card-text>
              <div>
                Created By: {{ item.createdBy }}
              </div>
              <div v-if="item.comments">
                <sb-text-area v-model="item.comments"
                              disabled
                              hide-details />
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-col>
  </div>
</template>
<script>
import { AUDIT_TRAIL_COLORS } from '@/config/constants';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    auditLogs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getAuditColor(status) {
      return AUDIT_TRAIL_COLORS[status];
    },
  },
};
</script>
