<template>
  <sb-page-content :title="pageTitle"
                   class="back-button-margin">
    <v-menu v-if="showServiceRequestStateKey"
            offset-y>
      <template #activator="{on}">
        <v-chip label
                dark
                tag="div"
                v-on="on">
          {{ serviceRequest.state.key }}
        </v-chip>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in srStates"
                     :key="index"
                     @click="changeSrState(item)">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <ValidationObserver ref="obs"
                        v-slot="{invalid, passes}">
      <v-row>
        <v-col v-if="!isCreateWorkAuthorization"
               cols="12">
          <sb-work-authorisation-list-item :loading="$wait.is('merchantDetailsLoading') || $wait.is('referenceListLoading')"
                                           :item="serviceRequest"
                                           :show-refresh="showRefresh"
                                           :show-download="showDownload"
                                           :is-downloading="$wait.is('getAttachmentAuthLetterLoading')"
                                           no-click
                                           @refresh-work-authorisation="refreshWorkAuthorisation"
                                           @download-work-authorisation-letter="downloadAuthLetter" />
        </v-col>
      </v-row>
      <v-row v-if="isLinkedServiceRequest"
             justify="center">
        <v-btn color="secondary"
               large
               outlined
               @click="()=> {showWorkAuthorisationDetails = !showWorkAuthorisationDetails; showEvents = false ; showAudit = false}">
          {{ showWorkAuthorisationDetails ? 'Show Less' : 'Show More' }}
        </v-btn>
      </v-row>

      <v-expand-transition>
        <v-row v-if="showWorkAuthorisationDetails">
          <v-col cols="6"
                 :class="{'d-flex align-stretch': !$wait.is('merchantDetailsLoading') && !$wait.is('referenceListLoading')}">
            <sb-work-auth-merchant :can-search="canSearchMerchants"
                                   :is-loading="$wait.is('getServiceRequestLoading')"
                                   :is-loading-merchant="$wait.is('merchantDetailsLoading') || $wait.is('referenceListLoading')"
                                   :merchant-details="serviceRequest.merchantDetails"
                                   title="Merchant Details"
                                   @merchant-update="refreshMerchantDetails" />
          </v-col>

          <v-col cols="6">
            <sb-work-auth-vehicle title="Vehicle Details"
                                  :service-request-loading="$wait.is('getServiceRequestLoading')"
                                  :read-only="readOnly"
                                  :can-update-authorisation="canUpdateAuthorisation"
                                  :service-request="serviceRequest"
                                  @vehicle-update="updateVehicleDetails"
                                  @vehicle-odometer-update="updateVehicleOdometerReading" />

            <sb-work-auth-driver title="Driver Details"
                                 :service-request-loading="$wait.is('getServiceRequestLoading')"
                                 :read-only="readOnly"
                                 :can-update-authorisation="canUpdateAuthorisation"
                                 :service-request="serviceRequest"
                                 @driver-update="updateDriverDetails" />
          </v-col>
          <v-col cols="12"
                 class="pb-0">
            <sb-work-auth-job title="Job Details"
                              :job-card="serviceRequest.authorisation.jobCard"
                              :totals="totals"
                              :can-update-authorisation="canUpdateAuthorisation"
                              :read-only="readOnly"
                              :merchant-id="merchantId"
                              :create-authorisation-loading="$wait.is('createAuthorisationLoading')"
                              :loading="$wait.is('getServiceRequestLoading')"
                              :disabled="$wait.any"
                              :estimate-fields-disabled="estimateFieldsDisabled"
                              :filtered-auth-work-required-items="filteredAuthWorkRequiredItems"
                              :min-estimated-completion-time="minEstimatedCompletionTime"
                              @item-update="updateJobCardItem"
                              @item-clear="clearJobCardItem"
                              @add-item="addItem"
                              @delete-item="deleteItem"
                              @job-update="updateJobDetails"
                              @discount-update-percent="updateDiscountSpecialPrice"
                              @special-price-update="updateDiscountSpecialPrice" />
          </v-col>

          <v-col v-if="displayCollection"
                 cols="12">
            <sb-work-auth-collection v-if="hideCollectionDetails"
                                     title="Collection Details"
                                     :can-edit-collection="canEditCollection"
                                     :loading="$wait.is('getServiceRequestLoading')"
                                     @collection-update="updateCollectionDetails" />
          </v-col>

          <v-col v-if="displayCustomerRate"
                 cols="12">
            <sb-work-auth-customer-rating title="Customer Rating"
                                          :loading="$wait.is('getServiceRequestLoading')"
                                          :can-customer-rate="canCustomerRate"
                                          :service-request="serviceRequest"
                                          @customer-rating-update="updateCustomerRatingDetails" />
          </v-col>

          <v-col v-if="displayInvoice"
                 cols="12">
            <sb-card :loading="$wait.is('getServiceRequestLoading')"
                     min-width="100%">
              <template slot="title">
                <v-card-title>
                  <sb-title title="Invoices" />
                  <v-spacer />
                  <v-tooltip v-if="!$wait.any && serviceRequest.authorisation.invoice.status.key"
                             left>
                    <template #activator="{on}">
                      <v-chip :color="getStatusColor(serviceRequest.authorisation.invoice.status.key)"
                              label
                              dark
                              tag="div"
                              v-on="on">
                        {{ serviceRequest.authorisation.invoice.status.value }}
                      </v-chip>
                    </template>
                    <span>Status</span>
                  </v-tooltip>
                </v-card-title>
              </template>

              <sb-attachment-upload :disabled="!canUploadInvoice"
                                    :default-file-net-file-type="'DOC_TYPE_INVOICE'"
                                    :hide-file-net-file-type="true"
                                    :service-request-id="serviceRequestNumber" />

              <!--<template v-if="canVoteInvoice"
                        slot="actions">
                <v-spacer />

                <v-btn color="error"
                       large
                       depressed
                       :disabled="$wait.any"
                       @click="displayDeclineInvoiceReasonDialog = true">
                  Decline
                </v-btn>

                <v-btn color="success"
                       large
                       depressed
                       class="ml-5"
                       :loading="$wait.is('updateServiceRequestLoading')"
                       :disabled="$wait.any"
                       @click="approveServiceRequest">
                  Approve
                </v-btn>
              </template>-->
            </sb-card>
          </v-col>
          <v-col v-if="canApproveDecline && !showOverrideMandate"
                 cols="12">
            <sb-card :loading="$wait.is('getServiceRequestLoading')"
                     min-width="100%">
              <template slot="title">
                <v-card-title>
                  <sb-title title="Approve or Decline Authorisation" />
                  <v-spacer />
                  <v-tooltip v-if="!$wait.any && serviceRequest.authorisation.invoice.status.key"
                             left>
                    <template #activator="{on}">
                      <v-chip :color="getStatusColor(serviceRequest.authorisation.invoice.status.key)"
                              label
                              dark
                              tag="div"
                              v-on="on">
                        {{ serviceRequest.authorisation.invoice.status.value }}
                      </v-chip>
                    </template>
                    <span>Status</span>
                  </v-tooltip>
                </v-card-title>
              </template>
              <sb-text-area v-model="declineReason"
                            label="Authorisation Reason"
                            ref-name="declineReason" />
              <template v-if="canApproveDecline"
                        slot="actions">
                <v-spacer />

                <v-btn color="error"
                       large
                       depressed
                       :disabled="$wait.any"
                       @click="declineServiceRequest">
                  Decline
                </v-btn>

                <v-btn color="success"
                       large
                       depressed
                       class="ml-5"
                       :loading="$wait.is('updateServiceRequestLoading')"
                       :disabled="$wait.any"
                       @click="approveServiceRequest">
                  Approve
                </v-btn>
              </template>
            </sb-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <sb-work-authMandate-override v-if="showOverrideMandate && isCallCentre"
                                    :is-loading="$wait.is('getServiceRequestLoading') || $wait.is('uploadAttachmentsLoading')"
                                    :is-action="$wait.is('updateServiceRequestLoading')"
                                    @override="overrideMandate"
                                    @decline="declineMandate" />
      <sb-work-auth-supporting-documents v-if="serviceRequestNumber !== undefined"
                                         :service-request-id="serviceRequestNumber" />
      <v-expand-transition>
        <v-row v-if="(showEvents || showAudit) && readOnly ">
          <v-col cols="12"
                 class="py-0">
            <div v-if="showEvents"
                 class="text-h2 my-5">
              Events
            </div>
            <div v-if="showAudit"
                 class="text-h2 my-5">
              Audit Trail
            </div>
          </v-col>
          <v-col v-if="showEvents"
                 cols="12">
            <Sb-event-timeline :reference-id="eventReferenceId"
                               :event-type="eventType" />
          </v-col>
          <v-col v-if="showAudit"
                 cols="12">
            <sb-audit-trail :is-loading="$wait.is('getServiceRequestLoading')"
                            :audit-logs="serviceRequest.audit.auditLog" />
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row v-if="serviceRequest.id && showWorkAuthorisationDetails"
             justify="center">
        <v-btn color="secondary"
               large
               outlined
               class="mx-2"
               @click="()=> {showEvents = !showEvents; showAudit = false}">
          {{ showEvents ? 'Show Less' : 'Show Events' }}
        </v-btn>
        <br>
        <v-btn color="secondary"
               large
               outlined
               @click=" ()=> {showAudit = !showAudit; showEvents = false}">
          {{ showAudit ? 'Show Less Audit Trail' : 'Show Audit Trail' }}
        </v-btn>
      </v-row>

      <v-container>
        <v-row>
          <v-spacer />

          <v-btn v-if="!readOnly"
                 color="secondary"
                 class="float-right"
                 large
                 depressed
                 :loading="$wait.is('createServiceRequestLoading')"
                 :disabled="$wait.any || invalid"
                 @click="passes(createWorkAuthorisation)">
            Create Authorisation
          </v-btn>

          <v-btn v-else-if="showUpdateAuthorisation"
                 color="secondary"
                 class="float-right"
                 large
                 depressed
                 :loading="$wait.is('updateServiceRequestLoading')"
                 :disabled="$wait.any || invalid"
                 @click="passes(updateWorkAuthorisation)">
            Update Authorisation
          </v-btn>
        </v-row>
      </v-container>
    </ValidationObserver>

    <sb-dialog-work-authorisation-response :service-request-number="serviceRequest.serviceRequestNumber" />
    <sb-dialog-decline-reason :decline-reasons="invoiceDeclineReasons"
                              :show-dialog="displayDeclineInvoiceReasonDialog"
                              @declined="declineServiceRequest"
                              @close="displayDeclineInvoiceReasonDialog = false" />
  </sb-page-content>
</template>

<script>
import _differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';

import SbWorkAuthMerchant from '@/components/authorisation/SbWorkAuthMerchant';
import SbWorkAuthVehicle from '@/components/authorisation/SbWorkAuthVehicle';
import SbWorkAuthDriver from '@/components/authorisation/SbWorkAuthDriver';
import SbWorkAuthJob from '@/components/authorisation/SbWorkAuthJob';
import SbDialogWorkAuthorisationResponse from '@/components/authorisation/SbDialogWorkAuthorisationResponse';
import SbDialogDeclineReason from '@/components/core/SbDialogDeclineReason';
import SbAttachmentUpload from '@/components/core/SbAttachmentUpload';
import SbWorkAuthorisationListItem from '@/components/authorisation/SbWorkAuthorisationListItem';
import SbEventTimeline from '@/widgets/event/SbEventTimeline';
import SbWorkAuthCollection from '@/components/authorisation/SbWorkAuthCollection';
import SbWorkAuthMandateOverride from '@/components/authorisation/SbWorkAuthMandateOverride';
import { base64toBlob } from '@/utils';
import Audit from '@/models/Audit';
import SbWorkAuthSupportingDocuments from '@/components/authorisation/SbWorkAuthSupportingDocuments';
import {
  AUTH_TABLE_HEADERS,
  SR_APPLICATION_TYPES,
  REFERENCE_LISTS,
  REQUEST_TYPE_LIST,
  SERVICE_TYPE_LIST,
  APPROVAL_STATUSES,
  SR_STATES,
  AUTH_STATUSES,
  EVENT_TYPES,
  SR_STATUSES,
  DOC_TYPES,
  EXLUDED_SERVICES,
} from '@/config/constants';

import SbAuditTrail from '@/widgets/event/SbAuditTrail';
import ServiceRequest from '../../../models/ServiceRequest';
import JobCardItemBattery from '../../../models/JobCardItemBattery';
// import JobCardItemBrake from '../../../models/JobCardItemBrake';
import JobCardItemOther from '../../../models/JobCardItemOther';
import JobCardItemTyre from '../../../models/JobCardItemTyre';
// import JobCardItemShock from '../../../models/JobCardItemShock';
import WorkItem from '../../../models/WorkItem';
import ServiceRequestAction from '../../../models/ServiceRequestAction';
// import JobCardItemExhausts from '../../../models/JobCardItemExhausts';

export default {
  components: {
    SbDialogWorkAuthorisationResponse,
    SbDialogDeclineReason,
    SbAttachmentUpload,
    ValidationObserver,
    SbWorkAuthorisationListItem,
    SbWorkAuthMerchant,
    SbWorkAuthVehicle,
    SbWorkAuthDriver,
    SbWorkAuthJob,
    SbEventTimeline,
    SbWorkAuthCollection,
    SbWorkAuthMandateOverride,
    SbAuditTrail,
    SbWorkAuthSupportingDocuments,
  },

  props: {
    serviceRequestNumber: {
      type: String,
      default: undefined,
    },
    isLinkedServiceRequest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showRefresh: true,
      isCreateWorkAuthorization: true,
      AUTH_TABLE_HEADERS,
      authWorkRequiredItems: [],
      merchantId: '',
      serviceRequest: new ServiceRequest(),
      minEstimatedCompletionTime: undefined,
      totals: {
        total: 0.0,
        vat: 0.0,
        grandTotal: 0.0,
      },
      showDeclineReason: false,
      declineReason: '',
      invoiceDeclineReasons: [],
      showEvents: false,
      eventType: EVENT_TYPES.TASK,
      sreqEventType: EVENT_TYPES.SREQ,
      eventReferenceId: '',
      showWorkAuthorisationDetails: !this.isLinkedServiceRequest,
      displayDeclineInvoiceReasonDialog: false,
      showAudit: false,
      hideCollectionDetails: false,
    };
  },

  computed: {
    srStates() {
      return SR_STATES || [];
    },

    authStates() {
      return AUTH_STATUSES || [];
    },

    pageTitle() {
      if (!this.isCreateWorkAuthorization) {
        return 'Work Authorisation';
      }
      return 'Create Work Authorisation';
    },

    showServiceRequestStateKey() {
      return this.isDevelopment && !this.isCreateWorkAuthorization;
    },

    readOnly() {
      return this.serviceRequestNumber !== undefined;
    },

    filteredAuthWorkRequiredItems() {
      return Object.values(this.authWorkRequiredItems).filter((x) => !x.default && !x.hidden);
    },

    displayAuthorisationResponseDialog: {
      get() {
        return this.$store.state.session.displayAuthorisationResponseDialog;
      },
      set(value) {
        this.$store.commit('session/displayAuthorisationResponseDialog', value);
      },
    },

    displayInvoice() {
      return this.serviceRequest.state.key === SR_STATES.SR_STATE_AUTHORISED && (this.authUser.isMerchant || this.authUser.isCallCentre);
    },

    canSearchMerchants() {
      return this.authUser.isCallCentre && this.serviceRequest.merchantDetails.merchant.merchantNumber === '';
    },

    canUploadInvoice() {
      return this.serviceRequest.state.key === SR_STATES.SR_STATE_AUTHORISED && (this.authUser.isMerchant || this.authUser.isCallCentre);
    },

    canVoteInvoice() {
      return this.serviceRequest.state.key === SR_STATES.SR_STATE_AUTHORISED && this.authUser.isPayments && this.isManagedAuthorisation;
    },

    displayCustomerRate() {
      const states = [SR_STATES.SR_STATE_CUSTOMER_RATING, SR_STATES.SR_STATE_COMPLETED];
      return states.includes(this.serviceRequest.state.key);
    },

    canCustomerRate() {
      return this.authUser.isCustomer && this.isManagedAuthorisation;
    },

    displayCollection() {
      const states = [SR_STATES.SR_STATE_AUTHORISED, SR_STATES.SR_STATE_COMPLETED, SR_STATES.SR_STATE_INVOICED];
      return states.includes(this.serviceRequest.state.key);
    },

    canUpdateAuthorisation() {
      const authStates = [AUTH_STATUSES.VALIDATION_FAILED.value, AUTH_STATUSES.AUTHORISATION_FAILED.value, AUTH_STATUSES.ERROR.value];
      return (
        ((this.authUser.isCallCentre || this.authUser.isMerchant) &&
          authStates.includes(this.serviceRequest.authorisationDetail.authorisationstatus) &&
          !this.isElectronicAuthorisation()) ||
        (this.isOverrideMandate() &&
          SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION_PORTAL.value === this.serviceRequest.serviceType.value)
      );
    },

    canEditCollection() {
      return this.authUser.isMerchant && this.isManagedAuthorisation;
    },

    canEditQuote() {
      const states = [SR_STATES.SR_STATE_RESUBMITTED];
      return (
        !this.readOnly &&
        states.includes(this.serviceRequest.state.key) &&
        (this.authUser.isMerchant || this.authUser.isMerchantInternal) &&
        this.isManagedAuthorisation
      );
    },

    canApproveDecline() {
      const states = [SR_STATES.SR_STATE_RESUBMITTED];
      return states.includes(this.serviceRequest.state.key) && this.authUser.isCallCentre && this.isManagedAuthorisation;
    },

    isManagedAuthorisation() {
      return this.serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION_PORTAL.key;
    },

    estimateFieldsDisabled() {
      return !this.serviceRequest.authorisation.jobCard.appointmentDate || !this.serviceRequest.authorisation.jobCard.appointmentTime;
    },
    showOverrideMandate() {
      return this.isOverrideMandate();
    },
    showDownload() {
      return this.serviceRequest.authorisationDetail.authorisationstatus === AUTH_STATUSES.AUTHORISED.value;
    },
    isCallCentre() {
      return this.authUser.isCallCentre;
    },
    showUpdateAuthorisation() {
      return this.isManagedAuthorisation && this.showOverrideMandate && this.authUser.isMerchant;
    },
  },

  watch: {
    'serviceRequest.authorisation.jobCard.sections': {
      handler(sections) {
        this.totals.total = 0.0;
        this.totals.vat = 0.0;
        this.totals.grandTotal = 0.0;

        Object.keys(sections).forEach((key) => {
          const section = sections[key];

          section.forEach((lineItem) => {
            const item = lineItem;
            const retailPriceExclVat = parseFloat(item.retailPriceExclVat) || this.$ci.parse(item.retailPriceExclVat);
            const specialPriceExclVat = parseFloat(item.specialPriceExclVat) || this.$ci.parse(item.specialPriceExclVat);
            let netAmountExclVat = 0;

            if (specialPriceExclVat > 0) {
              netAmountExclVat = retailPriceExclVat > specialPriceExclVat ? specialPriceExclVat : retailPriceExclVat;
            } else {
              netAmountExclVat = retailPriceExclVat;
            }
            if (this.isElectronicAuthorisation()) {
              if (specialPriceExclVat > 0) {
                netAmountExclVat = item.specialPriceExclVat;
              } else {
                const discount = ((100 - item.discountPercent) / 100).toFixed(2);
                netAmountExclVat = (item.retailPriceExclVat * discount).toFixed(2);
              }
            }

            item.totalNetAmount = item.qty * netAmountExclVat;

            item.netAmountExclVat = netAmountExclVat;
            item.totalVatAmount = item.netAmountExclVat * 0.15 * item.qty;
            item.totalAmount = item.totalNetAmount + item.totalVatAmount;

            this.totals.total += item.totalNetAmount;
            this.totals.vat = this.totals.total * 0.15;
            this.totals.grandTotal = this.totals.total + this.totals.vat;
          });
        });
      },
      deep: true,
      immediate: true,
    },

    'serviceRequest.authorisation.jobCard.workRequiredSelection': {
      handler(newValue, oldValue) {
        // Check which item was removed if any
        const removedItem = _differenceBy(oldValue, newValue, 'key');
        // If an item was removed
        if (removedItem.length > 0) {
          // Then clear its respective item from the sections
          // Calculations will be rerun from sections watcher
          this.serviceRequest.authorisation.jobCard.sections[removedItem[0].key] = [];
        }
      },
      deep: true,
    },

    'serviceRequest.authorisation.jobCard.estimatedCompletionDate': {
      handler() {
        if (!this.readOnly) {
          // Reset Time when Date changes
          this.serviceRequest.authorisation.jobCard.estimatedCompletionTime = '';
          // Check whether the date selected is the same as auth date
          if (this.serviceRequest.authorisation.jobCard.appointmentDate === this.serviceRequest.authorisation.jobCard.estimatedCompletionDate) {
            // Restrict time selection to current time and set time to current time
            this.minEstimatedCompletionTime = this.serviceRequest.authorisation.jobCard.appointmentTime;
          } else {
            // Remove restriction if date is not today
            this.minEstimatedCompletionTime = null;
          }
        }
      },
    },

    'serviceRequest.authorisation.jobCard.appointmentDate': {
      handler() {
        this.clearEstimateFields(true);
      },
    },

    'serviceRequest.authorisation.jobCard.appointmentTime': {
      handler() {
        this.clearEstimateFields();
      },
    },

    serviceRequestNumber: {
      handler(value) {
        this.isCreateWorkAuthorization = this.serviceRequestNumber === undefined;
        this.displayWorkAuthorisation(value);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('serviceRequests', [
      'createServiceRequest',
      'getServiceRequest',
      'updateServiceRequestEx',
      'actionServiceRequest',
      'getServiceRequestTasks',
    ]),
    ...mapActions('referenceLists', ['getReferenceList']),
    ...mapActions('merchants', ['getMerchantDetails', 'getMerchantServiceOfferings']),

    ...mapWaitingActions('serviceRequests', {
      createServiceRequest: 'createServiceRequestLoading',
      updateServiceRequestEx: 'updateServiceRequestLoading',
      getServiceRequest: 'getServiceRequestLoading',
    }),

    ...mapWaitingActions('referenceLists', {
      getReferenceList: 'referenceListLoading',
    }),

    ...mapWaitingActions('merchants', {
      getMerchantDetails: 'merchantDetailsLoading',
    }),

    ...mapActions('attachments', ['getAttachmentAuthLetter', 'uploadAttachments']),

    ...mapWaitingActions('attachments', {
      getAttachmentAuthLetter: 'getAttachmentAuthLetterLoading',
      uploadAttachments: 'uploadAttachmentsLoading',
    }),

    updateVehicleDetails(value) {
      this.serviceRequest.vehicle = value.vehicle;
      this.serviceRequest.vehicleDetails = value.vehicleDetails;
      this.serviceRequest.customer = value.customer;
      this.serviceRequest.client.id = value.customer.id;
      this.serviceRequest.client.number = value.customer.id;
      this.serviceRequest.client.name = value.customer.name;
      this.serviceRequest.client.costCentre.number = value.customer.costCentre;
      this.serviceRequest.client.costCentre.name = value.customer.costCentre;
    },

    updateVehicleOdometerReading(value) {
      this.serviceRequest.vehicleDetails.odometerReading = value;
    },

    updateDriverDetails(value) {
      if (value.label === 'Driver Contact Number') {
        this.serviceRequest.driverDetails.contactDetails.phoneNumber = value.value;
      } else {
        this.serviceRequest.driverDetails.fullname = value.value;
      }
    },
    updateJobDetails(value) {
      switch (value.label) {
        case 'Job Number':
          this.serviceRequest.authorisation.jobCard.jobNumber = value.value;
          break;
        case 'appointmentDate':
          this.serviceRequest.authorisation.jobCard.appointmentDate = value.value;
          break;
        case 'appointmentTime':
          this.serviceRequest.authorisation.jobCard.appointmentTime = value.value;
          break;
        case 'completionDate':
          this.serviceRequest.authorisation.jobCard.estimatedCompletionDate = value.value;
          break;
        case 'estimatedCompletionTime':
          this.serviceRequest.authorisation.jobCard.estimatedCompletionTime = value.value;
          break;
        case 'Work to be done':
          this.serviceRequest.authorisation.jobCard.workRequiredSelection = value.value;
          break;
        case 'Service Adviser Name':
          this.serviceRequest.authorisation.jobCard.serviceAdvisorName = value.value;
          break;
        case 'Authorisation Letter Email Address':
          this.serviceRequest.authorisation.jobCard.authorisationLetterEmail = value.value;
          break;
        case 'Service Adviser Contact Number':
          this.serviceRequest.authorisation.jobCard.serviceAdvisorContactNumber = value.value;
          break;
        case 'Pre Authorisation Number':
          this.serviceRequest.authorisation.jobCard.preAuthorisationNumber = value.value;
          break;
        case 'Comments':
          this.serviceRequest.authorisation.jobCard.jobComments = value.value;
          break;
        default:
      }
    },

    updateCollectionDetails(value) {
      switch (value.label) {
        case 'Collected By':
          this.serviceRequest.authorisation.collectionDetails.collectBy = value.value;
          break;
        case 'collectionDate':
          this.serviceRequest.authorisation.collectionDetails.collectionDate = value.value;
          break;
        case 'collectionTime':
          this.serviceRequest.authorisation.collectionDetails.collectionTime = value.value;
          break;
        default:
      }
    },

    updateCustomerRatingDetails(value) {
      switch (value.label) {
        case 'rating':
          this.serviceRequest.authorisation.serviceRating.rating = value.value;
          break;
        case 'Please tell us more about your experience':
          this.serviceRequest.authorisation.collectionDetails.comment = value.value;
          break;
        case 'Confirm that vehicle has been collected?':
          this.serviceRequest.authorisation.collectionDetails.confirmCollection = value.value;
          break;
        default:
      }
    },

    addItem(sectionKey) {
      let newItem = {};
      switch (sectionKey) {
        case this.authWorkRequiredItems.batteries.key:
          newItem = new JobCardItemBattery();
          break;
        // case this.authWorkRequiredItems.brakes.key:
        //   newItem = new JobCardItemBrake();
        //   break;
        case this.authWorkRequiredItems.other.key:
          newItem = new JobCardItemOther();
          break;
        case this.authWorkRequiredItems.tyres.key:
          newItem = new JobCardItemTyre();
          break;
        // case this.authWorkRequiredItems.shocks.key:
        //   newItem = new JobCardItemShock();
        //   break;
        // case this.authWorkRequiredItems.exhausts.key:
        //   newItem = new JobCardItemExhausts();
        //   break;
        default:
      }
      newItem.index = this.serviceRequest.authorisation.jobCard.sections[sectionKey].length;
      this.serviceRequest.authorisation.jobCard.sections[sectionKey].push(newItem);
    },

    async refreshWorkAuthorisation(serviceRequestNumber) {
      await this.displayWorkAuthorisation(serviceRequestNumber);
    },

    deleteItem({ item, section }) {
      const index = this.serviceRequest.authorisation.jobCard.sections[section].indexOf(item);
      this.serviceRequest.authorisation.jobCard.sections[section].splice(index, 1);
    },

    updateDiscountSpecialPrice(item, section, field) {
      const retailPriceExclVat = parseFloat(item.retailPriceExclVat) || this.$ci.parse(item.retailPriceExclVat);
      if (retailPriceExclVat > 0) {
        const index = this.serviceRequest.authorisation.jobCard.sections[section].indexOf(item);
        const discount = item.discountPercent / 100;
        const specialPriceExclVat = parseFloat(item.specialPriceExclVat) || this.$ci.parse(item.specialPriceExclVat);
        const lineItem = this.serviceRequest.authorisation.jobCard.sections[section][index];
        const currencyType = {
          style: 'currency',
          currency: 'ZAR',
        };
        switch (field) {
          case 'discount':
            if (discount > 0) {
              lineItem.specialPriceExclVat = `R ${(retailPriceExclVat * (1 - discount)).toFixed(2).toLocaleString('en-ZA', currencyType)}`;
            } else {
              lineItem.specialPriceExclVat = `R ${(0).toFixed(2).toLocaleString('en-ZA', currencyType)}`;
            }
            break;
          case 'special-price':
            if (specialPriceExclVat > 0) {
              lineItem.discountPercent = ((1 - specialPriceExclVat / retailPriceExclVat) * 100).toFixed(2);
            } else {
              lineItem.discountPercent = (0).toFixed(1);
            }
            break;
          default:
        }
      }
    },

    async createWorkAuthorisation() {
      const currentDate = moment().format('YYYY-MM-DD');
      const currentTime = moment().format('HH:mm:ss');

      this.serviceRequest.applicationType.key = SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_AUTHORISATIONS;
      this.serviceRequest.serviceType.key = SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION_PORTAL.key;
      this.serviceRequest.requestType.key = REQUEST_TYPE_LIST.SR_REQUEST_TYPE_REQUEST;
      this.serviceRequest.authorisation.requestDate = currentDate;
      this.serviceRequest.authorisation.requestTime = currentTime;

      // Create Authorisation SR
      const serviceRequestNumber = await this.createServiceRequest(this.serviceRequest);

      // Check if it was successful
      if (serviceRequestNumber) {
        // Set the SR Number and start polling for auth number response
        this.serviceRequest.serviceRequestNumber = serviceRequestNumber;
        this.displayAuthorisationResponseDialog = true;
      }
    },

    async displayWorkAuthorisation(value) {
      const getPromises = [];
      this.serviceRequest = new ServiceRequest();

      // Get reference lists from
      getPromises.push(this.getReferenceList(REFERENCE_LISTS.MERCHANT_SERVICE_OFFERINGS));
      getPromises.push(this.getReferenceList(REFERENCE_LISTS.INVOICE_DECLINE_REASONS));
      getPromises.push(this.getReferenceList(REFERENCE_LISTS.AUTH_WORK_REQUIRED_ITEMS));

      if (value) {
        // this.isCreateWorkAuthorization = false;
        // Get Service Request details for authorisation page.
        getPromises.push(this.getServiceRequest(value));
      }

      // Call all services in parallel
      const promiseResults = await Promise.all(getPromises);

      // Check if ServiceRequest lookup was done
      if (value) {
        const serviceRequest = promiseResults[3];
        this.serviceRequest = serviceRequest;
        this.serviceRequest.vehicle.client.name = this.serviceRequest.client.name;
        this.serviceRequest.vehicle.client.costCentre = this.serviceRequest.client.costCentre.number;
      }

      const refListMerchantServiceOfferings = promiseResults[0] || [];
      const refListInvoiceDeclineReasons = promiseResults[1] || [];
      const refListAuthWorkRequiredItems = promiseResults[2] || [];

      if (this.serviceRequest.merchantDetails.serviceOfferings.length <= 0) {
        this.serviceRequest.merchantDetails.serviceOfferings = [...refListMerchantServiceOfferings];
      }

      if (refListAuthWorkRequiredItems.length > 0) {
        this.addAuthWorkRequiredItems(refListAuthWorkRequiredItems);
        this.refreshAuthWorkRequiredItems();
      }

      this.invoiceDeclineReasons = [...refListInvoiceDeclineReasons];

      // Check if this is a create or view
      if (this.isCreateWorkAuthorization) {
        // Set merchantID from login user
        this.merchantId = this.authUser.username;
        // DEV USER: this.merchantId = '10049223';
      } else {
        // Set merchantID from service request
        this.merchantId = this.serviceRequest.merchantDetails.merchant.merchantNumber;
      }

      // Only lookup merchant directly, if auth user is logged
      // in as external Merchant
      if (this.authUser.isMerchant && !this.authUser.isStaffNumber) {
        await this.getMerchant(this.merchantId);
      }
      // Load tasks for the the eventTimeline
      const tasks = await this.getServiceRequestTasks({ serviceRequestNumber: this.serviceRequestNumber });
      this.eventReferenceId = tasks[0] ? tasks[0].id : '';
    },

    addAuthWorkRequiredItems(reflistWorkRequiredItems) {
      let checkWorkItem = {};
      let newWorkItem = {};
      reflistWorkRequiredItems.forEach((workItem) => {
        checkWorkItem = this.authWorkRequiredItems.find((item) => item.key.toLowerCase() === workItem.value.toLowerCase());
        if (checkWorkItem) {
          this.authWorkRequiredItems[checkWorkItem.key].hidden = true;
        } else {
          newWorkItem = new WorkItem(workItem);
          newWorkItem.key = workItem.value.toLowerCase();
          newWorkItem.hidden = true;
          newWorkItem.default = newWorkItem.key === 'other';
          if (!EXLUDED_SERVICES.includes(newWorkItem.key)) {
            this.authWorkRequiredItems[newWorkItem.key] = newWorkItem;
          }
        }
      });
    },

    refreshAuthWorkRequiredItems() {
      const refreshedItems = [];
      const workItem = Object.values(this.authWorkRequiredItems);
      workItem.forEach((item) => {
        const merchantServiceOffering = this.serviceRequest.merchantDetails.serviceOfferings.find(
          (offering) => offering.value.toLowerCase() === item.value.toLowerCase(),
        );
        refreshedItems[item.key] = item;
        refreshedItems[item.key].hidden = !(merchantServiceOffering && merchantServiceOffering.selected === true);
      });
      this.authWorkRequiredItems = refreshedItems;
    },

    async updateWorkAuthorisation() {
      const audit = this.getAuditLog('Update WorkAuthorisation');
      this.updateWorkAuthorisationRequest(audit).then(async (status) => {
        if (status !== undefined) {
          await this.displayWorkAuthorisation(this.serviceRequest.serviceRequestNumber);
        }
      });
    },

    updateJobCardItem(item) {
      if (item.selectedItem) {
        this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].partNo = item.selectedItem.id;
        this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].retailPriceExclVat = item.selectedItem.price || '0.00';
        this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].specialPriceExclVat =
          item.selectedItem.specialPrice || '0.00';
        this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].description = item.selectedItem.description;
      }
    },

    clearJobCardItem(item) {
      this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].partNo = '';
      this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].retailPriceExclVat = '0.00';
      this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].specialPriceExclVat = '0.00';
      this.serviceRequest.authorisation.jobCard.sections[item.sectionKey][item.index].description = 'Unknown';
    },

    async approveServiceRequest() {
      const payload = new ServiceRequestAction();
      payload.serviceRequestNumber = this.serviceRequest.serviceRequestNumber;
      payload.action = APPROVAL_STATUSES.APPROVED.value;
      payload.username = this.authUser.username;
      await this.actionServiceRequest(payload);

      this.$router.push({ name: 'ElectronicAuthorisationsViewWorkAuthorisation' });
    },

    async declineServiceRequest(reasons) {
      const payload = new ServiceRequestAction();
      payload.serviceRequestNumber = this.serviceRequest.serviceRequestNumber;
      payload.action = APPROVAL_STATUSES.DECLINED.value;
      payload.username = this.authUser.username;
      payload.reasons = reasons;
      payload.reason = this.declineReason;
      await this.actionServiceRequest(payload);

      this.$router.push({ name: 'ElectronicAuthorisationsViewWorkAuthorisation' });
    },

    getStatusColor(key) {
      let color;

      if (key === APPROVAL_STATUSES.APPROVED.value) {
        color = 'success';
      } else {
        color = 'error';
      }

      return color;
    },

    changeSrState(state) {
      this.serviceRequest.state.key = state;
    },

    refreshMerchantDetails(merchantDetails) {
      return this.getMerchant(merchantDetails.merchant.id);
    },

    async getMerchant(merchantId) {
      const merchant = await this.getMerchantDetails(merchantId);
      this.merchantId = merchant.id;

      // Get Merchant information
      this.serviceRequest.merchantDetails.merchant.merchantNumber = merchant.id;
      this.serviceRequest.merchantDetails.merchant.merchantName = merchant.name;
      this.serviceRequest.merchantDetails.merchant.contactDetails = merchant.contactDetails;

      // Get Merchant service offerings
      const merchantServiceOfferings = await this.getMerchantServiceOfferings(this.merchantId);
      this.refreshMerchantServiceOfferings(merchantServiceOfferings);
      this.refreshAuthWorkRequiredItems();
    },

    refreshMerchantServiceOfferings(merchantServiceOfferings) {
      this.serviceRequest.merchantDetails.serviceOfferings.forEach((serviceOffering) => {
        const offering = serviceOffering;
        offering.selected = merchantServiceOfferings.find((x) => x.key === serviceOffering.key) !== undefined;
        // Makes Array Reactive
        this.$set(this.serviceRequest.merchantDetails.serviceOfferings, serviceOffering, offering.selected);
      });
    },

    clearEstimateFields(isAppoinmentDateChanged) {
      if (!this.readOnly) {
        if (isAppoinmentDateChanged) {
          this.serviceRequest.authorisation.jobCard.appointmentTime = '';
        }
        this.serviceRequest.authorisation.jobCard.estimatedCompletionTime = '';
        this.serviceRequest.authorisation.jobCard.estimatedCompletionDate = '';
        this.minEstimatedCompletionTime = null;
      }
    },

    isOverrideMandate() {
      const status = [AUTH_STATUSES.VALIDATION_ERROR.value, AUTH_STATUSES.VALIDATION_FAILED.value, AUTH_STATUSES.AUTHORISATION_FAILED.value];
      return status.includes(this.serviceRequest.authorisationDetail.authorisationstatus);
    },
    isElectronicAuthorisation() {
      return this.serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_ELECTRONIC_AUTHORISATION.key;
    },
    async downloadAuthLetter() {
      const payload = {
        serviceRequestId: this.serviceRequest.serviceRequestNumber,
      };
      const authLetter = await this.getAttachmentAuthLetter(payload);
      if (!authLetter.status) {
        const blob = base64toBlob(authLetter.base64Content);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = authLetter.filename;
        link.click();
      }
    },

    async overrideMandate(event) {
      const audit = this.getAuditLog('Override Mandate', event.comment);
      this.serviceRequest.status.key = SR_STATUSES.SR_STATUS_OVERRIDE.value;
      this.updateWorkAuthorisationRequest(audit).then(async (status) => {
        if (status !== undefined) {
          await this.displayWorkAuthorisation(this.serviceRequest.serviceRequestNumber);
        } else {
          await this.uploadMandateOverrideAttachments(event.file, DOC_TYPES.Customer_Approval);
          await this.displayWorkAuthorisation(this.serviceRequest.serviceRequestNumber);
        }
      });
    },

    async declineMandate(event) {
      const audit = this.getAuditLog('Decline Mandate', event.comment);
      this.serviceRequest.status.key = SR_STATUSES.SR_STATUS_DECLINED.value;
      this.updateWorkAuthorisationRequest(audit).then(async (status) => {
        if (status !== undefined) {
          await this.displayWorkAuthorisation(this.serviceRequest.serviceRequestNumber);
        } else {
          await this.uploadMandateOverrideAttachments(event.file, DOC_TYPES.Customer_Rejection);
          await this.displayWorkAuthorisation(this.serviceRequest.serviceRequestNumber);
        }
      });
    },

    getAuditLog(event, comment = '') {
      const audit = new Audit();
      audit.event = event;
      audit.comments = comment;
      audit.auditLog = this.serviceRequest.audit.auditLog;
      return audit;
    },

    async uploadMandateOverrideAttachments(files, fileType) {
      if (files.length > 0) {
        const payload = {
          files,
          fileType,
          serviceRequestId: this.serviceRequest.serviceRequestNumber,
        };
        await this.uploadAttachments(payload);
      }
    },

    async updateWorkAuthorisationRequest(audit) {
      this.serviceRequest.vehicleDetails.odometerReading = Number(this.serviceRequest.vehicleDetails.odometerReading);
      const payload = {
        serviceRequest: this.serviceRequest,
        audit,
      };
      return this.updateServiceRequestEx(payload);
    },
  },
};
</script>
